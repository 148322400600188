<template>
    <section
        class="QuotePulloutBlock"
        :class="[
            {
                'QuotePulloutBlock--no-image': !props.slice.primary.image.url,
            },
            props.slice.primary.section_alignment
                ? 'QuotePulloutBlock--align-' +
                  props.slice.primary.section_alignment.toLowerCase()
                : '',
        ]"
        data-speed="1.1"
    >
        <SmartPicture
            v-if="props.slice.primary.image.url"
            :small-image="props.slice.primary.image.small.url"
            :medium-image="props.slice.primary.image.medium.url"
            :large-image="props.slice.primary.image.url"
            :large-breakpoint="750"
            :offset-frame="true"
            class="QuotePulloutBlock__image"
            data-speed="1.1"
        />
        <blockquote class="QuotePulloutBlock__wrapper" data-speed="0.9">
            <h3
                v-if="props.slice.primary.quote_title"
                class="QuotePulloutBlock__title"
            >
                {{ props.slice.primary.quote_title }}
            </h3>
            <PrismicRichText
                v-if="props.slice.primary.quote_description.length"
                :field="props.slice.primary.quote_description"
                class="QuotePulloutBlock__desc"
            />
            <footer>
                <p
                    v-if="props.slice.primary.quote_author"
                    class="QuotePulloutBlock__author"
                >
                    {{ props.slice.primary.quote_author }}
                </p>
                <cite
                    v-if="props.slice.primary.quote_author_subtitle"
                    class="QuotePulloutBlock__author-subtitle"
                >
                    {{ props.slice.primary.quote_author_subtitle }}
                </cite>
            </footer>
        </blockquote>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.QuotePulloutBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)
</script>

<style lang="scss">
.theme--white {
    .QuotePulloutBlock {
        color: var(--black);
    }

    .QuotePulloutBlock__desc {
        a {
            color: var(--black);
        }
    }
}

.QuotePulloutBlock {
    grid-column: 2 / span 22;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 3.75rem;
    margin-top: 5.5rem;

    @include medium-up {
        margin-top: 11.25rem;
    }

    @media (min-width: 750px) {
        flex-direction: row;
        align-items: center;
    }
}

.QuotePulloutBlock__image {
    width: 100%;
    margin-bottom: 1.25rem;

    @media (min-width: 750px) {
        min-width: 15.25rem;
        max-width: 22rem;
    }
}

.QuotePulloutBlock__wrapper {
    max-width: 43.75rem;
}

.QuotePulloutBlock__title {
    @include heading-3;

    margin-bottom: 1.75rem;
}

.QuotePulloutBlock__desc {
    @include body-md;

    font-style: italic;
    margin-bottom: 1.75rem;

    @media (min-width: 750px) {
        @include body-sm;
    }

    @include large-up {
        @include body-md;
    }

    a {
        color: var(--lime-green);
    }
}

.QuotePulloutBlock__author {
    @include body-lg;

    font-weight: var(--font-weight-bold);

    @media (min-width: 750px) {
        @include body-md;
    }

    @include large-up {
        @include body-lg;
    }
}

.QuotePulloutBlock__author-subtitle {
    @include body-lg;

    font-style: normal;

    @media (min-width: 750px) {
        @include body-md;
    }

    @include large-up {
        @include body-lg;
    }
}

/**

    Variations

*/

.QuotePulloutBlock--no-image {
    .QuotePulloutBlock__wrapper {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.QuotePulloutBlock--align-left {
    justify-content: flex-start;

    &.QuotePulloutBlock--no-image {
        .QuotePulloutBlock__wrapper {
            margin-left: 0;
        }
    }
}

.QuotePulloutBlock--align-right {
    justify-content: flex-end;

    &.QuotePulloutBlock--no-image {
        .QuotePulloutBlock__wrapper {
            margin-right: 0;
        }
    }
}
</style>
